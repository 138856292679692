import { alias, list, object, serializable } from 'serializr';

export class CancellationReasonsEntity {
  @serializable(alias('key'))
  private _key: string;

  @serializable(
    alias('childReasons', list(object(CancellationReasonsEntity)))
  )

  private _childReasons?: CancellationReasonsEntity[] = [];

  @serializable(alias('value'))
  private _value?: string|boolean;

  get key() {
    return this._key;
  }

  get childReasons() {
    return this._childReasons;
  }

  get value() {
    return this._value;
  }
}
