import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AcceptCancelInputComponent } from '@shared/components/accept-cancel-input/accept-cancel-input.component';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component';
import { FeedbackMessagesComponent } from '@shared/components/feedback-messages/feedback-messages.component';
import { FileListComponent } from '@shared/components/file-list/file-list.component';
import { FileUploadStatusPipe } from '@shared/components/file-upload/assets/file-upload-status.pipe';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { FiltersComponent } from '@shared/components/filters/filters.component';
import { IconDataTupleComponent } from '@shared/components/icon-data-tuple/icon-data-tuple.component';
import { IconInputComponent } from '@shared/components/icon-input/icon-input.component';
import { ListItemComponent } from '@shared/components/list-item/list-item.component';
import { LoadingIndicatorComponent } from '@shared/components/loading-indicator/loading-indicator.component';
import { MenuBoxComponent } from '@shared/components/menu-box/menu-box.component';
import { MenuButtonComponent } from '@shared/components/menu-button/menu-button.component';
import { MenuItemComponent } from '@shared/components/menu-item/menu-item.component';
import {
  DistributionInvoiceBatchDetailModalComponent,
} from '@shared/components/modal/content/batch-detail-modal/distribution-invoice-batch-detail-modal.component';
import { ConfirmModalComponent } from '@shared/components/modal/content/confirm-modal/confirm-modal.component';
import { TextModalComponent } from '@shared/components/modal/content/text-modal/text-modal.component';
import { ModalHostComponent } from '@shared/components/modal/modal-host/modal-host.component';
import { MultiselectComponent } from '@shared/components/multiselect/multiselect.component';
import { NavbarComponent } from '@shared/components/navbar/navbar.component';
import { NavigationMenuComponent } from '@shared/components/navigation-menu/navigation-menu.component';
import { OutletPageComponent } from '@shared/components/outlet-page/outlet-page.component';
import { PagedResultsComponent } from '@shared/components/paged-results/paged-results.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { RadioComponent } from '@shared/components/radio/radio.component';
import { RouterOutletComponent } from '@shared/components/router-outlet/router-outlet.component';
import { SiteNotificationComponent } from '@shared/components/site-notification/site-notification.component';
import { StatsBoxComponent } from '@shared/components/stats-box/stats-box.component';
import { TypeaheadComponent } from '@shared/components/typeahead/typeahead.component';
import { DistributionRecipientListTypePipe } from '@shared/pipes/distribution-recipient-list-type.pipe';
import { DistributionTypePipe } from '@shared/pipes/distribution-type.pipe';
import { DistributionUserPipe } from '@shared/pipes/distribution-user.pipe';
import { FileSizePipe } from '@shared/pipes/file-size.pipe';
import { ObjToKeysPipe } from '@shared/pipes/obj-to-keys.pipe';
import { UcFirstPipe } from '@shared/pipes/uc-first.pipe';
import { UsersNamePipe } from '@shared/pipes/users-name.pipe';
import { AsFormGroupPipe } from '@shared/pipes/as-form-group.pipe';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { FfNgxControlErrorDirective } from '@fagforbundet/ngx-components';

@NgModule({
  declarations: [
    AcceptCancelInputComponent,
    BreadcrumbComponent,
    ButtonComponent,
    CheckboxComponent,
    ConfirmModalComponent,
    DistributionInvoiceBatchDetailModalComponent,
    DistributionUserPipe,
    DistributionRecipientListTypePipe,
    DistributionTypePipe,
    DropdownComponent,
    FeedbackMessagesComponent,
    FileListComponent,
    FileSizePipe,
    FileUploadComponent,
    FileUploadStatusPipe,
    FiltersComponent,
    IconDataTupleComponent,
    IconInputComponent,
    ListItemComponent,
    LoadingIndicatorComponent,
    MenuBoxComponent,
    MenuButtonComponent,
    MenuItemComponent,
    MultiselectComponent,
    ModalHostComponent,
    NavbarComponent,
    NavigationMenuComponent,
    ObjToKeysPipe,
    OutletPageComponent,
    PagedResultsComponent,
    PaginationComponent,
    RadioComponent,
    RouterOutletComponent,
    SiteNotificationComponent,
    StatsBoxComponent,
    TextModalComponent,
    TypeaheadComponent,
    UsersNamePipe,
    AsFormGroupPipe,
    UcFirstPipe,
    ToggleSwitchComponent,
  ],
  imports: [
    A11yModule,
    AngularSvgIconModule,
    CommonModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    RouterModule,
    FfNgxControlErrorDirective,
  ],
  exports: [
    AcceptCancelInputComponent,
    BreadcrumbComponent,
    ButtonComponent,
    CheckboxComponent,
    DistributionUserPipe,
    DistributionRecipientListTypePipe,
    DistributionTypePipe,
    DropdownComponent,
    FeedbackMessagesComponent,
    FileListComponent,
    FileSizePipe,
    FileUploadComponent,
    FileUploadStatusPipe,
    FiltersComponent,
    IconDataTupleComponent,
    IconInputComponent,
    ListItemComponent,
    LoadingIndicatorComponent,
    MenuBoxComponent,
    MenuButtonComponent,
    MenuItemComponent,
    ModalHostComponent,
    MultiselectComponent,
    NavbarComponent,
    NavigationMenuComponent,
    NgxPaginationModule,
    ObjToKeysPipe,
    OutletPageComponent,
    PagedResultsComponent,
    PaginationComponent,
    RadioComponent,
    ReactiveFormsModule,
    RouterOutletComponent,
    SiteNotificationComponent,
    StatsBoxComponent,
    TypeaheadComponent,
    UsersNamePipe,
    AsFormGroupPipe,
    UcFirstPipe,
    ToggleSwitchComponent,
  ],
  providers: [
    FileSizePipe,
    FileUploadStatusPipe,
    UcFirstPipe,
  ],
})
export class SharedModule {
}
