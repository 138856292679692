import { HttpClient, HttpContext } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EmployerUser } from '@core/entities/employer/employer-user/employer-user.interface';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Observable, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmployerService {
  #self$!: Observable<EmployerUser>;
  readonly #httpClient = inject(HttpClient);

  getSelfCached(context?: HttpContext): Observable<EmployerUser> {
    if (!this.#self$) {
      this.#self$ = this.#httpClient
        .get<{ user: EmployerUser }>(
          EnvironmentHelper.fetchAPIBase('employer') + '/v1/users/self',
          {context}
        )
        .pipe(
          map((response) => response.user),
          shareReplay(1)
        );
    }
    return this.#self$;
  }
}
