<label
  (keyup.enter)="toggle()"
  (keyup.space)="toggle()"
  [class.disabled]="disabled || formCtrl.disabled"
  [class.error]="validated && formCtrl.invalid"
  class="custom radio"
  [tabindex]="0"
  >

  @if (formCtrl) {
    <input
      [formControl]="formCtrl"
      ffNgxControlError
      [value]="value"
      [tabindex]="-1"
      type="radio"
      />
  }

  <svg-icon
    [applyClass]="true"
    [class.active]="formCtrl.value === value"
    [class.disabled]="disabled || formCtrl.disabled"
    [class.colored]="validated && formCtrl.invalid"
    class="icon radio"
    src="assets/icons/form_radio.svg"
    role="presentation"
  ></svg-icon>

  @if (labelText) {
    <div [title]="helpText || null">
      {{labelText}}
      @if (descriptionText) {
        <div class="description">{{descriptionText}}</div>
      }
    </div>
  }
</label>

