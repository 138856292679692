import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SsnValidationResponseEntity } from "@core/entities/response/ssn-validation-response.entity";
import { EnvironmentHelper } from "@core/helpers/environment.helper";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { deserialize, serialize } from "serializr";
import { IdUserEntity } from "@modules/user-management/entities/id-user.entity";
import { IdSaveUserRequestEntity } from "@modules/user-management/entities/id-save-user-request.entity";
import { IdPagedUsersEntity } from "@modules/user-management/entities/id-paged-users.entity";

@Injectable({
  providedIn: 'root',
})
export class IdUserApiService {
  readonly #httpClient = inject(HttpClient); 

  checkValidSsn(ssn: string): Observable<SsnValidationResponseEntity> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('id') + '/v1/validate/ssn/' + ssn).pipe(
      map((response: object) => {
        return deserialize(SsnValidationResponseEntity, response);
      }),
    );
  }

  existsBySsn(ssn: string): Observable<boolean> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('id') + '/v1/users/exists/by-ssn/' + ssn).pipe(
      map((response: { exists: boolean }) => (response.exists)),
    );
  }

  getSelf(context?: HttpContext): Observable<IdUserEntity> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('id') + '/v1/users/self', {
      context,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<{ user: object }>) => {
        return deserialize(IdUserEntity, response.body?.user);
      }),
    );
  }

  getUser(userUuid: string): Observable<IdUserEntity> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('id') + '/v1/users/' + userUuid).pipe(
      map((response: { user: object }) => {
        return deserialize(IdUserEntity, response.user);
      }),
    );
  }

  getUsers(pagedEntity: IdPagedUsersEntity): Observable<IdPagedUsersEntity> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('id') + '/v1/users', {
      observe: 'response',
      params: pagedEntity.apiRequestParams,
    }).pipe(
      map((response: HttpResponse<{ users: object[], count: string }>) => {
        if (response.status === 204) {
          pagedEntity.users = [];
          pagedEntity.currentPage = 1;
          pagedEntity.totalItems = 0;
        } else {
          // Keep existing paged entity with filters etc, update only the newly fetched props
          pagedEntity.users = deserialize(IdUserEntity, response.body?.users ?? []);
          pagedEntity.totalItems = response.body == null ? 0 : parseInt(response.body.count);
        }

        return pagedEntity;
      }),
    );
  }

  postUser(postRequest: IdSaveUserRequestEntity): Observable<IdUserEntity> {
    return this.#httpClient.post(EnvironmentHelper.fetchAPIBase('id') + '/v1/users', {
      user: serialize(postRequest),
    }).pipe(
      map((response: { user: object }) => {
        return deserialize(IdUserEntity, response.user);
      }),
    );
  }

  putUser(userId: string, putUserRequest: IdSaveUserRequestEntity): Observable<IdUserEntity> {
    return  this.#httpClient.put(EnvironmentHelper.fetchAPIBase('id') + '/v1/users/' + userId, {
      user: serialize(putUserRequest)
    }).pipe(
      map((response: {user: object}) => {
        return deserialize(IdUserEntity, response.user);
      })
    );
  }

  syncUser(user: IdUserEntity): Observable<IdUserEntity> {
    return this.#httpClient.post(EnvironmentHelper.fetchAPIBase('id') + '/v1/users/' + user.uuid + '/sync', {
      syncSystem: 'fane2'
    }).pipe(
      map((response: {user: object}) => {
        return deserialize(IdUserEntity, response.user);
      })
    );
  }
}
