import { NgModule } from '@angular/core';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { AuthOidcCustomStore } from '@core/services/stores/auth-oidc-custom.store';
import { IdApiInterface } from '@environments/assets/environment.interface';
import { environment } from '@environments/environment';
import { AbstractSecurityStorage, AuthModule, LogLevel } from 'angular-auth-oidc-client';


@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: EnvironmentHelper.fetchAPIBase('id'),
        clientId: (environment.APIS.id as IdApiInterface).adminWebClientId,
        ignoreNonceAfterRefresh: true, // OidcClient bug, until fixed we have to ignore
        logLevel: environment.debugOIDC ? LogLevel.Debug : LogLevel.None,
        maxIdTokenIatOffsetAllowedInSeconds: 300,
        postLoginRoute: '/login',
        postLogoutRedirectUri: environment.frontend.postLogoutRedirectUri,
        redirectUrl: environment.frontend.redirectUrl,
        renewTimeBeforeTokenExpiresInSeconds: 240, // OidcClient bug/quirk, until fixed must be less than id_token.exp TTL (staging=300s)
        responseType: 'code',
        scope: 'openid',
        silentRenew: true,
        tokenRefreshInSeconds: 30,
        triggerAuthorizationResultEvent: true,
        triggerRefreshWhenIdTokenExpired: false, // false = avoid using id_token.exp to trigger refresh of tokens. This allows us to avoid disableIdTokenValidation=true
        useRefreshToken: true,
        customParamsAuthRequest: {
          acr_values: "http://schemas.openid.net/pape/policies/2007/06/multi-factor",
        }
      },
    }),
  ],
  providers: [{ provide: AbstractSecurityStorage, useClass: AuthOidcCustomStore }],
  exports: [AuthModule],
})
export class AuthConfigModule {}
