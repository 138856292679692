import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { concatMap, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  readonly #oidcSecurityService = inject(OidcSecurityService);

  /**
   * Intercept requests, add Bearer token if applicable
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If the request is to the local SVG registry, the req.url will be relative without protocol prefix
    // and will break if trying to new URL(req.url), so pass through (early return) all .svg requests.
    if (req.url.endsWith('.svg')) {
      return next.handle(req);
    }

    // Some ID-API endpoints require a token, while these specifically require that
    // no token is supplied (ie we want to retrieve one)
    const noAuthEndpoints = [
      '/v1/oauth/authorize',
      '/v1/oauth/end-session',
      '/v1/oauth/revoke',
      '/v1/oauth/token',
      '/.well-known/jwks',
      '/.well-known/openid-configuration',
    ];

    const path = new URL(req.url).pathname;

    if (noAuthEndpoints.includes(path)) {
      return next.handle(req);
    }

    // Add Bearer token to request and continue
    return this.#oidcSecurityService.getAccessToken().pipe(
      first(),
      concatMap((token: string) => {
        req = req.clone({
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token
          })
        });

        return next.handle(req);
      })
    );
  }
}
