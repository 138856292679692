import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { PermissionStatus } from '@core/enums/permission-status.enum';
import { MaintenanceHelper } from '@core/helpers/maintenance.helper';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceInterceptor implements HttpInterceptor {
  readonly #authService = inject(AuthService);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((e: HttpErrorResponse) => {
        if (MaintenanceHelper.isMaintenanceResponse(e) && !req.context.get(SKIP_MAINTENANCE_INTERCEPTOR)) {
          this.#authService.handlePermission(PermissionStatus.MAINTENANCE);
        }

        return throwError(() => e);
      }),
    );
  }
}

export const SKIP_MAINTENANCE_INTERCEPTOR = new HttpContextToken<boolean>(() => false);
