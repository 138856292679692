import { MembershipOrganizationalUnit } from '@core/models/membership/membership-organizational-unit';
import { GetOrganizationalUnitsResponseDto } from '@core/services/api/dtos/membership/get-organizational-units.response-dto';

export const getOrganizationalUnitsMapper = function (
  dto: GetOrganizationalUnitsResponseDto,
): MembershipOrganizationalUnit[] {
  return dto.organizationalUnits.map((ou) => {
    return {
      name: ou.name,
      type: ou.type,
      uuid: ou.uuid,
      fane2OrganizationalUnitId: ou.fane2OrganizationalUnitId,
      fane2Name: ou.fane2Name,
      status: {
        code: ou.status.code,
        name: ou.status.name,
        fane2Id: ou.status.fane2Id,
      },
      departmentNumber: ou.departmentNumber,
      organizationNumber: ou.organizationNumber,
    };
  });
};
