import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeNB from '@angular/common/locales/nb';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { MockInterceptor } from '@core/interceptors/mock.interceptor';
import { SharedModule } from '@shared/shared.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth-config.module';
import {
  ffNgxFormErrorProvider, ffNgxLoadingScreenProvider,
  ffNgxRequestsBundleProvider,
  ffNgxStorageBundleProvider,
} from '@fagforbundet/ngx-components';
import { storageBundleConfig } from '@config/storage-bundle.config';
import { controlErrorMessages } from '@config/control-errors/control-error.config';
import { requestBundleConfig } from '@config/request-bundle.config';
import { MaintenanceInterceptor } from '@core/interceptors/maintenance.interceptor';
import { loadingScreenConfig } from '@config/loading-screen.config';
import { environment } from '@environments/environment';
import {ngxMembershipApplicationProvider} from "@fagforbundet/ngx-membership-application";

registerLocaleData(localeNB);

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [
    AppComponent,
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    AppRoutingModule,
    AuthConfigModule,
    BrowserModule,
    CoreModule,
    SharedModule,
  ],
  providers: [
    ngxMembershipApplicationProvider({
      environment: environment.type,
    }),
    ffNgxLoadingScreenProvider(loadingScreenConfig),
    ffNgxRequestsBundleProvider(requestBundleConfig),
    ffNgxStorageBundleProvider(storageBundleConfig),
    ffNgxFormErrorProvider(controlErrorMessages),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'nb',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'NOK',
    },
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
