import { Component, inject, OnInit } from "@angular/core";
import { LoginResponse, OidcSecurityService } from "angular-auth-oidc-client";
import { finalize, tap } from "rxjs/operators";
import { AuthService } from "@core/services/auth.service";
import { FfNgxLoadingScreenService } from '@fagforbundet/ngx-components';
import { LoadingScreenDependencies } from '@core/enums/loading-screen-dependencies';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  readonly #authService = inject(AuthService);
  readonly #loadingScreenService = inject(FfNgxLoadingScreenService);
  readonly #oidcSecurityService = inject(OidcSecurityService);

  ngOnInit(): void {
    this.#loadingScreenService.addDependant(LoadingScreenDependencies.AUTHENTICATION);

    this.#oidcSecurityService.checkAuth().pipe(
      tap((loginResponse: LoginResponse) => {
        if (loginResponse.isAuthenticated) {
          if (this.#authService.getStoredRememberMe()) {
            this.#authService.setStoredUser(
              loginResponse.userData.sub,
              loginResponse.userData.given_name,
              loginResponse.userData.family_name
            );
          }
        }
      }),
      finalize(() => {
        this.#authService.authCompleted();
        this.#loadingScreenService.removeDependant(LoadingScreenDependencies.AUTHENTICATION);
      }),
    ).subscribe();
  }
}
