import { HttpClient, HttpContext, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { CustomHttpParameterCodec } from '@core/utils/custom-http-parameter-codec';
import { StrikelogCollectiveAgreementEntity } from '@modules/strikelog/entities/strikelog-collective-agreement.entity';
import { StrikelogCompetenceCenterEntity } from '@modules/strikelog/entities/strikelog-competence-center.entity';
import { StrikelogDailyReportEntity } from '@modules/strikelog/entities/strikelog-daily-report.entity';
import { StrikelogEmployerWorkplaceEntity } from '@modules/strikelog/entities/strikelog-employer-workplace.entity';
import { StrikelogEmployerEntity } from '@modules/strikelog/entities/strikelog-employer.entity';
import { STRIKELOG_USER_ENTITY_SCHEMA } from '@modules/strikelog/entities/strikelog-model-schema.entity';
import {
  StrikelogPagedCompetenceCentersEntity,
} from '@modules/strikelog/entities/strikelog-paged-competence-centers.entity';
import { StrikelogPagedDailyReportsEntity } from '@modules/strikelog/entities/strikelog-paged-daily-reports.entity';
import { StrikelogPagedEmployersEntity } from '@modules/strikelog/entities/strikelog-paged-employers.entity';
import { StrikelogPaginationEntity } from '@modules/strikelog/entities/strikelog-pagination.entity';
import { StrikelogUserEntity } from '@modules/strikelog/entities/strikelog-user.entity';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { deserialize } from 'serializr';

@Injectable({
  providedIn: 'root'
})
export class StrikelogService {
  readonly #httpClient = inject(HttpClient);

  getCollectiveAgreements(): Observable<StrikelogCollectiveAgreementEntity[]> {
    let params = new HttpParams({encoder: new CustomHttpParameterCodec()});
    params = params.set('type', 'COLLECTIVE');
    params = params.set('counterpartType', 'CENTRAL');

    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('proxy') + '/v1/agreements', {
      params,
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<{agreements: object[]}>) => {
        if (response.status === 204) {
          return [];
        } else {
          return deserialize(StrikelogCollectiveAgreementEntity, response.body?.agreements ?? []);
        }
      })
    );
  }

  getCompetenceCenters(pagedEntity: StrikelogPagedCompetenceCentersEntity): Observable<StrikelogPagedCompetenceCentersEntity> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('strikelog') + '/v1/competence-centers', {
      observe: 'response',
      params: pagedEntity.apiRequestParams
    }).pipe(
      map((response: HttpResponse<{competenceCenters: object[], paginationMetadata?: object}>) => {
        if (response.status === 204) {
          pagedEntity.centers = [];
          pagedEntity.pagination.currentPage = 1;
          pagedEntity.pagination.totalItems = 0;
        } else {
          pagedEntity.centers = deserialize(StrikelogCompetenceCenterEntity, response.body?.competenceCenters ?? []);
          pagedEntity.pagination = deserialize(StrikelogPaginationEntity, response.body?.paginationMetadata);
        }
        return pagedEntity;
      })
    );
  }

  getDailyReports(pagedEntity: StrikelogPagedDailyReportsEntity): Observable<StrikelogPagedDailyReportsEntity> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('strikelog') + '/v3/daily-reports', {
      observe: 'response',
      params: pagedEntity.apiRequestParams
    }).pipe(
      map((response: HttpResponse<{dailyReports: object[], paginationMetadata?: object}>) => {
        if (response.status === 204) {
          pagedEntity.reports = [];
          pagedEntity.pagination.currentPage = 1;
          pagedEntity.pagination.totalItems = 0;
        } else {
          const existingPagination = pagedEntity.pagination;
          // Keep existing paged entity with filters etc, update only the newly fetched props
          pagedEntity.reports = deserialize(StrikelogDailyReportEntity, response.body?.dailyReports ?? []);
          if (response.body?.paginationMetadata) {
            pagedEntity.pagination = deserialize(StrikelogPaginationEntity, response.body.paginationMetadata);
          }
          // API does not return query, re-apply it to the overwritten (deserialized) object
          pagedEntity.pagination.query = existingPagination.query;
        }

        return pagedEntity;
      })
    );
  }

  getEmployers(pagedEntity: StrikelogPagedEmployersEntity): Observable<StrikelogPagedEmployersEntity> {
    return this.#httpClient.get(
      EnvironmentHelper.fetchAPIBase('strikelog') + '/v3/employers',
      {
        observe: 'response',
        params: pagedEntity.apiRequestParams
      }).pipe(
      map((response: HttpResponse<{employers: object[], paginationMetadata?: object}>) => {
        if (response.status === 204) {
          pagedEntity.employers = [];
          pagedEntity.pagination.currentPage = 1;
          pagedEntity.pagination.totalItems = 0;
        } else {
          const existingPagination = pagedEntity.pagination;
          // Keep existing paged entity with filters etc, update only the newly fetched props
          pagedEntity.employers = deserialize(StrikelogEmployerEntity, response.body?.employers ?? []);
          if (response.body?.paginationMetadata) {
            pagedEntity.pagination = deserialize(StrikelogPaginationEntity, response.body.paginationMetadata);
          }
          // API does not return query, re-apply it to the overwritten (deserialized) object
          pagedEntity.pagination.query = existingPagination.query;
        }
        return pagedEntity;
      })
    );
  }

  getEmployerWorkplaces(employerUuid: string): Observable<StrikelogEmployerWorkplaceEntity[]> {
    return this.#httpClient.get(
      EnvironmentHelper.fetchAPIBase('strikelog') + '/v3/employers/' +  employerUuid + '/workplaces',
      { observe: 'response'}
    ).pipe(
      map((response: HttpResponse<{workplaces: object[]}>) => {
        if (response.status === 204) {
          return []
        } else {
          return deserialize(StrikelogEmployerWorkplaceEntity, response.body?.workplaces ?? []);
        }
      }),
      shareReplay(),
    );
  }

  getSelf(context?: HttpContext): Observable<StrikelogUserEntity> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('strikelog') + '/v2/users/self', {
      context,
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<{user: object}>) => {
        return deserialize<StrikelogUserEntity>(STRIKELOG_USER_ENTITY_SCHEMA, response.body?.user);
      })
    );
  }
}
