<label
  [attr.for]="inputId"
  [class.disabled]="isDisabled"
  [class.focused]="focused"
  [class.bold]="bold"
  [id]="labelId"
  class="custom checkbox"
  [tabindex]="-1"
  >
  <input
    (click)="toggle($event)"
    (focusin)="focused=true"
    (focusout)="focused=false"
    (keydown.enter)="toggle($event)"
    (keydown.space)="toggle($event)"
    [formControl]="checkCtrl"
    ffNgxControlError
    [attr.id]="inputId"
    type="checkbox"
    [tabindex]="0"
    >

  @if (!processing) {
    <svg-icon
      [applyClass]="true"
      [class.active]="isChecked"
      [class.disabled]="isDisabled"
      class="icon checkbox"
      src="assets/icons/form_checkbox.svg"
      role="presentation"
    ></svg-icon>
  }

  @if (labelText) {
    <div>
      {{labelText}}
      @if (descriptionText) {
        <div class="description">{{descriptionText}}</div>
      }
    </div>
  }
</label>

